.sm-list {
  @include flexColumn;

  &__title {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding: 0 0 20px 19px;
  }

  &__container {
    &-noitems {
      text-align: center;
    }

    &-item {
      @include flexAlignCenter;
      @include spaceBetween;
      height: 47px;
      padding: 0 8px 0 9px;
      margin-bottom: 2px;
      background: rgba(255, 255, 255, 0.1);

      & > div {
        @include flexAlignCenter;

        span {
          font-size: 16px;
        }

        .sm-image {
          padding-right: 14px;

          img {
            height: 29px;
            width: 29px;
            border-radius: 50%;
          }
        }
      }

      &:first-child {
        border-radius: 20px 20px 2px 2px;
      }

      &:last-child {
        border-radius: 2px 2px 20px 20px;
      }

      &__cont {
        padding: 7px 0 7px 40px;

        div {
          span:first-child {
            color: var(--Gray, #676767);
            padding-right: 10px;
          }

          span:nth-child(2) {
            font-size: 15px;
          }
        }
      }

      &__active {
        border-radius: 20px !important;
        background-color: rgba(255, 255, 255, 0.2);
      }

      &__rounded-prev {
        //border-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
      }

      &__rounded-next {
        //border-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border-top-left-radius: 20px !important;
      }
    }

    .ReactVirtualized__Grid {
      //height: 100% !important;
      width: 100% !important;

      .ReactVirtualized__Grid__innerScrollContainer {
        //height: 100% !important;
        width: 100% !important;
      }
    }
  }

  &__show {
    @include flexRow;
    @include flexCenter;
    padding-top: 9px;

    span {
      color: rgba(255, 255, 255, 0.3);
      font-size: 16px;
    }

    .sm-image {
      img {
        height: 19px;
        width: 19px;
      }
    }
  }
}
