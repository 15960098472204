.sm-button {
  border-radius: 40px;
  background: #ffaa13;
  @include flexCenter;
  height: 47px;
  padding: 0 15px;

  .sm-image {
    padding-right: 7px;

    img {
      height: 29px;
    }
  }

  button,
  a {
    @include flexCenter;
    outline: none;
    border: none;
    text-transform: uppercase;
    background-color: transparent;
    font-weight: bold;
    font-size: 16px;
    appearance: none;
    margin: 0;
    padding: 0;
    border-radius: 0;
    text-align: inherit;
    -webkit-tap-highlight-color: transparent;
  }

  &__light {
    background-color: #ffaa13;

    button,
    a {
      color: #000000;
    }

    &:active {
      background-color: #f8d394;
    }
  }

  &__dark {
    background-color: #000000;
    border: 1px solid #ffaa13;

    button,
    a {
      color: #ffaa13;
    }

    &:active {
      border: 1px solid #f8d394;

      button {
        color: #f8d394;
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.icon-padding {
  padding: 0 15px 0 10px !important;
}
