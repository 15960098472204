.language-item {
  &__wrapper {
    @include flexRow;
    @include flexAlignCenter;
    background: rgba(255, 255, 255, 0.1);
    margin-bottom: 2px;
    height: 47px;
    padding: 0 14px;

    span {
      font-size: 16px;
    }

    .sm-image {
      padding-right: 20px;

      img {
        height: 29px;
        width: 29px;
      }
    }

    &:first-child {
      border-radius: 20px 20px 2px 2px;
    }

    &:last-child {
      border-radius: 2px 2px 20px 20px;
    }
  }

  &__active {
    background: rgba(255, 255, 255, 0.2);
  }
}
