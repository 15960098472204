.sm-bg-grey {
  background-color: rgba(255, 255, 255, 0.1);
}

.sm-border-none {
  border: none !important;
}

.sm-code-text {
  color: var(--Gray, #676767);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 0 16px;
}

.arrow-up {
  transform: rotate(180deg);
}

.label-text {
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 0 20px 19px;
  font-size: 18px;
}

.color-grey {
  color: var(--Gray, #676767);
}

.pd-b-10 {
  padding-bottom: 10px;
}

.pd-b-20 {
  padding-bottom: 20px;
}

.pd-b-25 {
  padding-bottom: 25px;
}

.pd-l-19 {
  padding-left: 19px;
}

.p-lr30 {
  padding: 0 30px !important;
}

.w-75 {
  width: 75px;
}

.close-btn {
  height: 29px;
  width: 29px;

  img {
    height: 100%;
  }
}

.m-l-r {
  & > div {
    &:nth-child(1) {
      margin-right: 5px;
    }

    &:nth-child(2) {
      margin-left: 5px;
    }
  }
}

.spinner-gif {
  animation: rotation 1.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
