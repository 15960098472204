.sm-select {
  width: 100%;

  &__options {
    height: fit-content;
    width: 100%;
    background: transparent;
    padding-top: 2px;

    &-item {
      @include flexRow;
      @include spaceBetween;
      padding: 0 8px;
      margin: 2px 0;
      height: 47px;

      &__logo {
        @include flexRow;
        @include flexAlignCenter;

        span {
          color: #ffffff;
          font-size: 16px;
        }

        .sm-image {
          padding-right: 14px;

          img {
            border-radius: 50%;
            height: 29px;
            width: 29px;
          }
        }
      }

      &__info {
        @include flexAlignCenter;

        > div {
          border-radius: 10px;
          background: rgba(77, 77, 77, 0.2);
          height: 31px;
          padding: 0 7px 0 10px;
          @include flexRow;
          @include flexAlignCenter;

          .sm-image {
            padding-left: 5px;

            img {
              height: 17px;
            }
          }
        }

        .from {
          font-size: 14px;
          color: #ffffff;
        }
      }

      background: rgba(255, 255, 255, 0.1);

      //&:first-child {
      //  border-radius: 20px 20px 2px 2px;
      //}
      //
      //&:last-child {
      //  border-radius: 2px 2px 20px 20px;
      //}
    }

    .rounded {
      border-radius: 20px;
    }

    .semi-rounded {
      &:first-child {
        border-radius: 20px 20px 2px 2px;
      }

      &:last-child {
        border-radius: 2px 2px 20px 20px;
      }
    }
  }

  &__selected {
    @include flexColumn;

    &-label {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #ffffff;
      padding: 0 0 20px 19px;
    }

    &-container {
      border-radius: 17px;
      //border: 1px solid rgba(255, 255, 255, 0.20);
      background: rgba(255, 255, 255, 0.2);
      @include flexAlignCenter;
      @include spaceBetween;
      padding: 0 8px;
      margin: 2px 0;
      height: 47px;

      &__icon {
        @include flexAlignCenter;
        @include spaceBetween;

        span {
        }

        .sm-image {
          padding-right: 14px;

          img {
            border-radius: 50%;
            height: 29px;
            width: 29px;
          }
        }
      }

      &__btn {
        @include flexCenter;

        .price {
          margin-right: 13px;
          border-radius: 15px;
          background: rgba(77, 77, 77, 0.2);
          @include flexCenter;
          padding: 5px;

          .sm-image {
            margin-left: 5px;

            img {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  &__more-less {
    @include flexRow;
    @include flexCenter;
    padding-top: 9px;

    span {
      color: rgba(255, 255, 255, 0.3);
      font-size: 16px;
    }

    img {
      height: 19px;
      width: 19px;
    }
  }
}
