.header {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 40px;
  //margin-top: 1rem;
  height: 66px;
  @include flexAlignCenter;
  //position: absolute;
  justify-content: center;

  > div {
    @include flexAlignCenter;
    background: #262626;
    border-radius: 20px;
    width: 100%;
    //margin: 0 16px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
  }

  &__logo {
    margin: 8px;

    .sm-image {
      img {
        height: 50px;
        width: 50px;
      }
    }
  }

  &__info {
    @include flexColumn;
    margin-left: 7px;

    span:nth-child(1) {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    span:nth-child(2) {
      color: #ffaa13;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
}
