.settings-menu {
  width: 100%;
  @include flexColumn;

  &__label {
    text-transform: uppercase;
    color: #ffffff;
    padding: 0 0 20px 19px;
    font-size: 18px;
  }

  &__wrapper {
    &-item {
      @include flexRow;
      @include flexAlignCenter;
      height: 47px;
      padding: 0 8px 0 9px;
      margin-bottom: 2px;
      background: rgba(255, 255, 255, 0.1);

      span {
        font-size: 16px;
        padding-left: 14px;
      }

      &:first-child {
        border-radius: 20px 20px 2px 2px;
      }

      &:last-child {
        border-radius: 2px 2px 20px 20px;
      }

      &:active {
        background: rgba(255, 255, 255, 0.2);

        span {
          color: #ffaa13;
        }
      }
    }
  }
}
