.welcome-page {
  height: 100vh;
  @include flexCenter;

  &__container {
    @include flexAlignCenter;
    @include spaceBetween;
    height: 100%;

    &-wrapper {
      @include flexColumn;
      @include flexAlignCenter;
    }

    &-text {
      margin-top: 30px;
      text-align: center;

      span {
        color: #ffaa13;
      }
    }
  }
}
