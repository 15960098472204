.order-container {
  @include flexColumn;

  label {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #ffffff;
    padding-bottom: 1rem;
    padding-left: 1rem;
  }

  &__wrapper {
    &-item {
      background: rgba(255, 255, 255, 0.2);
      padding: 0.5rem;
      margin: 0.3rem 0;

      &__logo {
        @include flexRow;
        @include flexAlignCenter;

        .sm-image {
          padding-right: 0.8rem;

          img {
            border-radius: 50%;
            height: 40px;
          }
        }
      }

      &:first-child {
        border-radius: 20px 20px 2px 2px;
      }

      &:last-child {
        border-radius: 2px 2px 20px 20px;
      }
    }
  }
}
