.nav-bar {
  position: fixed;
  z-index: 1;
  bottom: 30px;
  @include flexContentCenter;
  height: 60px;

  &__wrapper {
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.15);
    width: 157px;
    @include flexContentCenter;
    @include flexAlignCenter;
    padding: 0.5rem;
    backdrop-filter: blur(5px);

    & > div {
      height: 30px;
      width: 30px;
      padding: 0 16px;
      @include flexContentCenter;

      svg {
        height: 100%;
      }
    }
  }
}
