.page-wrapper__children {
  padding-top: 1rem;
  width: 100%;
  overflow-y: auto;
  @include flexAlignCenter;
  @include flexColumn;
  padding-bottom: 7rem;
  //height: 74%;

  //@media only screen and (max-width: 400px) {
  //  height: 70%;
  //}
  //@media only screen and (max-width: 375px) {
  //  height: 66%;
  //}
  //@media only screen and (max-width: 240px) {
  //  height: 75%;
  //}
}
