.sm-input {
  //padding-bottom: 3rem;
  width: 100%;

  &__wrapper {
    height: fit-content;
    border-radius: 17px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 10px !important;
    @include flexAlignCenter;

    &--img-label {
      @include flexCenter;

      img {
        height: 25px;
        width: 25px;
      }

      .spinner-gif {
        height: 30px;
        width: 30px;
      }
    }

    &--img-search_label {
      @include flexCenter;

      img {
        height: 20px;
        width: 20px;
      }
    }

    input {
      outline: none;
      font-weight: 100;
      box-sizing: border-box;
      width: 100%;
      padding: 0 0.5rem;
      background-color: transparent;
      color: #ffffff;
      font-size: 17px;
      border: none;
      height: 47px;

      &::placeholder {
        font-style: normal;
        font-weight: 100;
        font-size: 17px;
        color: rgba(255, 255, 255, 0.2);
      }
    }

    &--reversed {
      flex-direction: row-reverse;
    }

    &-counter {
      padding-right: 1rem;
      color: var(--Gray, #676767);
    }
  }

  &__label {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #ffffff;
    padding: 0 0 20px 19px;
  }

  &__error {
    padding: 0;
    margin: 0;
    color: #e53935;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 20px;
    text-align: left;
    padding-left: 1rem;
  }

  // Number input type's arrows disappeared
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
