@import "assets/scss/mixins";

body {
  height: 100vh;
}

body * {
  font-family: SFProTextRegular;
  color: #ffffff;

  // remove scrollbar
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

#root {
  height: 100%;

  .main {
    height: 100%;
    background: #000000;
    position: relative;
    padding: 0 16px 0 16px;
    @include flexAlignCenter;
    flex-direction: column;
  }
}
