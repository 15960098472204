.container {
  width: 100%;

  &__row {
    @include flexRow;
  }

  &__col {
    @include flexColumn;
  }

  &__between {
    justify-content: space-between;
  }

  &__around {
    justify-content: space-around;
  }

  &__center {
    display: flex;
    justify-content: center;
  }
}
