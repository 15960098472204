.money-input {
  @include flexCenter;

  &__integral {
    font-size: 35px;
  }

  &__decimal {
    font-size: 30px;
  }

  &__label {
    padding-right: 10px;
    font-size: 35px;
  }

  input {
    border: none;
    outline: none;
    background: none;
    font-size: 35px;
    caret-color: transparent;

    &::placeholder {
      color: white;
      opacity: 1;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.w-20 {
  width: 75px;
}

.w-0 {
  width: 0.01px;
}
